import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {InventoryEntryCatalogs, InventoryEntryForm} from "@/modules/inventoryEntries/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): InventoryEntryForm => {
    const id = uuid();

    return {
        id,
        code: '',
        documentableType: '',
        description: '',
        status: 'to_received',
        items: [
            {
                id: uuid(),
                locationId: '',
                documentableItemType: '',
                documentableItemId: '',
                itemId: '',
                unitId: '',
                inventoryEntryId: id,
                quantity: 0,
                quantityToReceive: 0,
                quantityReceived: 0
            }
        ]
    }
}

const inventoryEntryForm: Ref<InventoryEntryForm> = ref(defaultValues())

const useInventoryEntry = () => {
    const clearForm = () => inventoryEntryForm.value = defaultValues()

    const createInventoryEntry = async (inventoryEntryForm: InventoryEntryForm) => {
        return await store.dispatch('inventoryEntries/createInventoryEntry', inventoryEntryForm)
    }

    const updateInventoryEntry = async (inventoryEntryForm: InventoryEntryForm) => {
        return await store.dispatch('inventoryEntries/updateInventoryEntry', inventoryEntryForm)
    }

    const getInventoryEntry = async (id: string): Promise<InventoryEntryForm> => {
        const {data} = await store.dispatch('inventoryEntries/getInventoryEntry', id)

        return data;
    }

    const getCatalogs = async (): Promise<InventoryEntryCatalogs> => {
        const {data} = await store.dispatch('inventoryEntries/getCatalogs', companyId.value)

        return data;
    }

    return {
        inventoryEntryForm,
        clearForm,
        createInventoryEntry,
        updateInventoryEntry,
        getInventoryEntry,
        getCatalogs
    }
}

export default useInventoryEntry;
