import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {ItemCatalogs, ItemForm} from "@/modules/items/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): ItemForm => {
    const id = uuid();

    return {
        id,
        code: "",
        name: "",
        description: "",
        type: "",
        categoryId: "",
        saleTaxId: "",
        purchaseTaxId: "",
        status: "active",
        companyId: companyId.value,
        measurementUnits: [
            {
                id: uuid(),
                itemId: id,
                measurementUnitId: '',
                base: 'yes',
                conversionFactor: 1
            }
        ]
    }
}

const itemForm: Ref<ItemForm> = ref(defaultValues())

const useItem = () => {
    const clearForm = () => itemForm.value = defaultValues()

    const createItem = async (itemForm: ItemForm) => {
        return await store.dispatch('items/createItem', itemForm)
    }

    const updateItem = async (itemForm: ItemForm) => {
        return await store.dispatch('items/updateItem', itemForm)
    }

    const getItem = async (id: string): Promise<ItemForm> => {
        const {data} = await store.dispatch('items/getItem', id)

        return data;
    }

    const getCatalogs = async (): Promise<ItemCatalogs> => {
        const {data} = await store.dispatch('items/getCatalogs', companyId.value)

        return data;
    }

    return {
        itemForm,
        clearForm,
        createItem,
        updateItem,
        getItem,
        getCatalogs
    }
}

export default useItem;
