
import GeneralData from '../components/GeneralData.vue'
import Items from '../components/Items.vue'
import useInventoryEntry from "@/modules/inventoryEntries/composables/useInventoryEntry";
import toastr from "toastr";
import {onMounted, Ref, ref} from "vue";
import router from "@/router";
import {InventoryEntryCatalogs} from "@/modules/inventoryEntries/interfaces";

export default {
    components: {
        GeneralData,
        Items
    },
    props: ['id'],
    setup(props: { id: string }) {
        const {clearForm, inventoryEntryForm, updateInventoryEntry, getCatalogs, getInventoryEntry} = useInventoryEntry()
        const sending = ref(false)
        const loading = ref(true)
        const catalogs: Ref<InventoryEntryCatalogs | null> = ref(null)
        clearForm();
        onMounted(async () => {
            catalogs.value = await getCatalogs()
            inventoryEntryForm.value = await getInventoryEntry(props.id)

            loading.value = false
        })
        return {
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'inventory_entries'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updateInventoryEntry(inventoryEntryForm.value)
                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'inventory_entries'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
