import {baseURL} from "@/api/erpApi";

const useSelect2 = (query = '') => {
    const select2center = {
        ajax: {
            url: () => `${baseURL}/accounting_centers/select2${query}`
        },
    }

    const select2account = {
        ajax: {
            url: () => `${baseURL}/accounting_accounts/select2${query}`
        },
    }

    const select2provider = {
        ajax: {
            url: () => baseURL + '/providers/select2'
        }
    }

    const select2location = {
        ajax: {
            url: () => baseURL + '/locations/select2'
        }
    }

    const select2item = {
        ajax: {
            url: () => baseURL + '/items/select2'
        }
    }

    const select2pettycash = {
        ajax: {
            url: () => baseURL + '/petty_cash/select2'
        }
    }

    return {
        select2center,
        select2account,
        select2provider,
        select2location,
        select2item,
        select2pettycash
    }
}

export default useSelect2;
