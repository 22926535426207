
import {onMounted, ref, watch} from "vue";
import useInventoryEntry from "@/modules/inventoryEntries/composables/useInventoryEntry";
import usePanel from "@/composables/usePanel";

const {inventoryEntryForm} = useInventoryEntry();
export default {
    props: ['catalogs', 'mode'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const code = ref('')
        const documentableType = ref('')
        const description = ref('')
        const status = ref('')


        onMounted(() => {
            code.value = inventoryEntryForm.value.code
            documentableType.value = inventoryEntryForm.value.documentableType
            description.value = inventoryEntryForm.value.description
            status.value = inventoryEntryForm.value.status
        })

        watch(code, val => inventoryEntryForm.value.code = val)
        watch(documentableType, val => inventoryEntryForm.value.documentableType = val)
        watch(description, val => inventoryEntryForm.value.description = val)
        watch(status, val => inventoryEntryForm.value.status = val)

        return {
            showPanel,
            togglePanel,
            code,
            documentableType,
            description,
            status,
            inventoryEntryForm
        }
    }
}
